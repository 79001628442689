/////////////////////////////////////
// IMPORTING LIBRARIES
/////////////////////////////////////

import React from "react";

/////////////////////////////////////
// COMPONENT: ROTATINGPLAY
/////////////////////////////////////

const RotatingPlay: React.FC = () => {
  const text = [
    "∙",
    "N",
    "E",
    "E",
    "L",
    " ",
    "B",
    "A",
    "N",
    "S",
    "A",
    "L",
    "∙",
    "N",
    "E",
    "E",
    "L",
    " ",
    "B",
    "A",
    "N",
    "S",
    "A",
    "L",
    "∙",
    "N",
    "E",
    "E",
    "L",
    " ",
    "B",
    "A",
    "N",
    "S",
    "A",
    "L",
  ];
  return (
    <div>
      <div className="h-[120px] w-[120px] font-bold text-gray-700 rounded-full bg-white ">
        <div className="w-[120px] h-[120px] absolute rotate-text">
          {text.map((letter: string, index: number) => {
            return (
              <span
                key={index}
                className="left-[50%] text-[10px] absolute transform-origin-0-100 "
                style={{ transform: `rotate(${index * 10}deg)` }}
              >
                {letter}
              </span>
            );
          })}
        </div>
      </div>
    </div>
  );
};

/////////////////////////////////////
// EXPORTING ROTATINGPLAY
/////////////////////////////////////

export default RotatingPlay;
