/////////////////////////////////////
// IMPORTING LIBRARIES
/////////////////////////////////////

import React, { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import PlayBox from "./PlayBox";
import { useData } from "../../../api/DataContext";

/////////////////////////////////////
// INTERFACE: PROPS
/////////////////////////////////////

interface Props {
  filter: string;
}

/////////////////////////////////////
// COMPONENT: WORK
/////////////////////////////////////

const Work: React.FC<Props> = ({ filter }) => {
  const { projectsData } = useData();

  const [hoveredWorkImage, setHoveredWorkImage] = React.useState<any | null>(
    null
  );
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => {
        setIsHovered(false);
        setHoveredWorkImage(null);
      }}
    >
      <p className=" text-black font-bold py-2 px-4 rounded-lg"></p>
      <PlayBox isHovered={isHovered} currentWorkImage={hoveredWorkImage} />
      {projectsData &&
        projectsData
          .filter((work: any) => work.playBoxShow === true)
          .sort((a: any, b: any) => {
            const aFilter = (a.WorkFilter || "").toString().toUpperCase();
            const bFilter = (b.WorkFilter || "").toString().toUpperCase();

            if (
              aFilter.includes(filter.toUpperCase()) &&
              !bFilter.includes(filter.toUpperCase())
            ) {
              return -1;
            } else if (
              !aFilter.includes(filter.toUpperCase()) &&
              bFilter.includes(filter.toUpperCase())
            ) {
              return 1;
            }
            return 0;
          })
          .map(({ title, link, Tags, mainImage }: any) => {
            return (
              <a href={link} target="_blank">
                <div
                  key={uuidv4()}
                  onMouseEnter={() => {
                    setHoveredWorkImage(mainImage);
                  }}
                  onMouseLeave={() => {
                    setHoveredWorkImage(null);
                  }}
                  className="group"
                >
                  <div className="h-[2px] w-full bg-gray-200 lg:w-full"></div>
                  <div className="flex flex-row items-center justify-between">
                    <p className="group-hover:text-gray-500 transition-all duration-300 ease-in flex sm:text-5xl text-4xl mt-10 mb-10">
                      {title}
                    </p>
                    <p className="sm:block hidden group-hover:text-gray-500 ">
                      {Tags.map((tag: string, index: number) => (
                        <span key={tag} className="text-sm">
                          {tag}
                          {index !== Tags.length - 1 && (
                            <span>&nbsp;&amp;&nbsp;</span>
                          )}
                        </span>
                      ))}
                    </p>
                  </div>
                </div>
              </a>
            );
          })}
    </div>
  );
};

/////////////////////////////////////
// EXPORTING WORK
/////////////////////////////////////

export default Work;
