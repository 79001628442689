/////////////////////////////////////
// IMPORTING LIBRARIES
/////////////////////////////////////

import { useGSAP } from "@gsap/react";
import React, { useRef } from "react";
import gsap from "gsap";

/////////////////////////////////////
// INTERFACE: PROPS
/////////////////////////////////////

interface Props extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
  text: string;
  target?: "_blank" | "_self" | "_parent" | "_top";
  link?: string;
  className?: string;
  theme: "dark" | "light";
}

/////////////////////////////////////
// FUNCTION: BUTTON
/////////////////////////////////////

const Button: React.FC<Props> = ({
  text,
  link,
  className,
  theme,
  target,
  onClick,
  type,
  ...rest
}): JSX.Element => {
  const container = useRef<HTMLAnchorElement>(null);

  useGSAP(
    () => {
      if (container.current) {
        const button = container.current;
        const buttonText = button.querySelector(".text");
        const buttonBackground = button.querySelector(".background");

        gsap.set(buttonBackground, { scaleY: 0 });

        button.addEventListener("mouseleave", () => {
          gsap.to(button, { x: 0, y: 0, duration: 0.3 });
          gsap.to(buttonText, {
            x: 0,
            y: 0,
            color: theme === "dark" ? "#FFF" : "#202121",
            duration: 0.3,
          });
          gsap.to(buttonBackground, {
            scaleY: 0,
            transformOrigin: "top",
            duration: 0.3,
          });
        });

        button.addEventListener("mousemove", (e: any) => {
          const boundingRect = button.getBoundingClientRect();
          const relX = e.pageX - boundingRect.left;
          const relY = e.pageY - boundingRect.top;
          const buttonMovement = 15;
          const textMovement = 30;

          gsap.to(button, {
            x:
              ((relX - boundingRect.width / 2) / boundingRect.width) *
              buttonMovement,
            y:
              ((relY - boundingRect.height / 2) / boundingRect.height) *
              buttonMovement,
            ease: "power2.out",
            duration: 0.3,
          });

          gsap.to(buttonText, {
            x:
              ((relX - boundingRect.width / 2) / boundingRect.width) *
              textMovement,
            y:
              ((relY - boundingRect.height / 2) / boundingRect.height) *
              textMovement,
            duration: 0.3,
            color: theme === "dark" ? "#202121" : "#FFF",
          });

          gsap.to(buttonBackground, {
            scaleY: 1,
            transformOrigin: "top",
            ease: "power2.out",
            duration: 0.3,
          });
        });
      }
    },
    { scope: container }
  );

  return (
    <a
      ref={container}
      href={link}
      target={target}
      className={`cursor-pointer ${className ? className : ""}`}
      onClick={onClick}
      type={type}
      {...rest}
    >
      <div
        className="items-center justify-center w-60 p-5 rounded-full flex flex-row   font-normal relative overflow-hidden"
        style={{
          color: theme === "dark" ? "#FFF" : "#202121",
          backgroundColor: theme === "dark" ? "#202121" : "#FFF",
          transition: "transform 0.3s",
          border: `2px solid ${theme === "dark" ? "#202121" : "#FFF"}`,
        }}
      >
        <div
          style={{
            backgroundColor: theme === "dark" ? "#FFF" : "#202121",
          }}
          className="background absolute inset-0  origin-top transform scaleY-0"
        ></div>
        <div className="text-xs md:text-md text z-2 flex justify-center items-center">
          {text}
        </div>
      </div>
    </a>
  );
};

/////////////////////////////////////
// EXPORTING BUTTON
/////////////////////////////////////

export default Button;
