/////////////////////////////////////
// IMPORTING LIBRARIES
/////////////////////////////////////

import React from "react";

/////////////////////////////////////
// OJBECT: TAGS
/////////////////////////////////////

const tags = {
  h2: ["<H2>", "</H2>"],
  p: ["<P>", "</P>"],
  div: ["<DIV>", "</DIV>"],
};

/////////////////////////////////////
// INTERFACE: PROPS
/////////////////////////////////////

interface Props {
  type: keyof typeof tags;
  text: string;
}

/////////////////////////////////////
// COMPONENT: TAGS
/////////////////////////////////////

const Tags: React.FC<Props> = ({ type, text }): JSX.Element => {
  const tag = tags[type] || tags["div"];
  return <div className="font-bold">{tag[0] + text + tag[1]}</div>;
};

/////////////////////////////////////
// EXPORTING TAGS
/////////////////////////////////////

export default Tags;
