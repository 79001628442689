import React, { useEffect } from "react";
import gsap from "gsap";
import { useLoading } from "../helpers/LoadingContext";

const Navbar: React.FC = (): JSX.Element => {
  const { loading } = useLoading();
  useEffect(() => {
    if (loading === false) {
      gsap.from(".nav-link", {
        opacity: 0,
        y: -20,
        duration: 1,
        delay: 0.5,
        stagger: 0.2,
      });
    }
  }, [loading]);

  return (
    <nav style={{ backgroundColor: "#1C1D20" }}>
      <div className="mx-auto max-w-full px-6 sm:px-6 lg:px-10 text-[12.5px]">
        <div className="nav-link flex h-20 items-center justify-between">
          <div className="sm:block text-white flex-1 items-center justify-center">
            ©️ Neel Bansal
          </div>
          <div className="sm:ml-6 sm:block">
            <div className="flex space-x-4">
              <a
                href="/#about"
                className=" text-white px-3 py-2"
                aria-current="page"
              >
                About
              </a>
              <a href="/work" className="nav-link text-white px-3 py-2">
                Work
              </a>
              <a href="/#contact" className="nav-link text-white px-3 py-2">
                Contact
              </a>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
