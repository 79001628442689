/////////////////////////////////////
// IMPORTING MODULES
/////////////////////////////////////

import React from "react";
import triangle404 from "../../assets/images/404.png";
import Button from "../../components/Button";

/////////////////////////////////////
// COMPONENT: ERROR PAGE
/////////////////////////////////////

const ErrorPage: React.FC = (): JSX.Element => {
  return (
    <div
      style={{ background: "#1C1D20" }}
      className="min-h-screen w-full h-full"
    >
      <div className="min-h-screen w-full h-full">
        <div className="flex-col flex md:flex-row min-h-screen w-full h-full justify-center items-center space-x-20">
          <div>
            <img alt="error triangle image" src={triangle404} />
          </div>
          <div className="flex justify-center items-center">
            <hr
              className="hidden md:block"
              style={{
                border: "none",
                color: "red",
                backgroundColor: "#979797",
                width: "3px",
                height: "275px",
              }}
            />
          </div>
          <div className="text-white flex flex-col space-y-3">
            <br />
            <h2 className="font-bold text-xl">This page could not be found</h2>
            <p>
              This page does not exist, please press the button below <br />
              to be redirected to the home page
            </p>
            <br />
            <Button theme="light" text="Back Home" link="/" />
          </div>
        </div>
      </div>
    </div>
  );
};

/////////////////////////////////////
// EXPORTING ERROR PAGE
/////////////////////////////////////

export default ErrorPage;
