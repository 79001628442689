/////////////////////////////////////
// IMPORTING LIBRARIES
/////////////////////////////////////

import React, { SetStateAction, useEffect, useState } from "react";
import { BsArrowUpRight } from "@react-icons/all-files/bs/BsArrowUpRight";
import { getTimezoneOffset, validateEmail } from "../../../helpers/helper";
import axios from "axios";
import Button from "../../../components/Button";

/////////////////////////////////////
// COMPONENT: FOOTER
/////////////////////////////////////

const Footer: React.FC = (): JSX.Element => {
  /**
   * Renders the Footer component.
   *
   * @returns {JSX.Element} The rendered Footer component.
   */
  const [emailValue, setEmailValue] = useState<string>("");
  const [messageValue, setMessageValue] = useState<string>("");
  const [nameValue, setNameValue] = useState<string>("");
  const [subjectValue, setSubjectValue] = useState<string>("");
  const [submitted, setSubmitted] = useState<boolean>(false);

  const GOOGLE_FORM_LINK =
    "https://docs.google.com/forms/d/e/1FAIpQLSeSoz9ga8LoC04tM_6bv1-Wzx5t0iMgmU5XjCm4FTzTeNXZRA/formResponse";
  const GOOGLE_FORM_NAME_ID = "entry.2005620554";
  const GOOGLE_FORM_EMAIL_ID = "entry.1045781291";
  const GOOGLE_FORM_MESSAGE_ID = "entry.839337160";
  const GOOGLE_FORM_SUBJECT_ID = "entry.1065046570";

  const sendMessage = (): void => {
    const formData = new FormData();
    formData.append(GOOGLE_FORM_NAME_ID, nameValue);
    formData.append(GOOGLE_FORM_EMAIL_ID, emailValue);
    formData.append(GOOGLE_FORM_MESSAGE_ID, messageValue);
    formData.append(GOOGLE_FORM_SUBJECT_ID, subjectValue);
    axios
      .post(GOOGLE_FORM_LINK, formData)
      .then(() => {
        setSubmitted(true);
      })
      .catch(() => {
        // There will be an error due to CORS but it still sends the form data
        setSubmitted(true);
      });
  };
  return (
    <footer
      id="contact"
      style={{ backgroundColor: "#202121" }}
      className="text-center min-[1400px]:text-left p-5"
    >
      <div className="p-28 md:p-10 md:pt-20">
        <h1 className="sm:text-6xl text-3xl md:text-5xl text-white">
          Contact Me
        </h1>
        <br />
        <div className="h-[1px] w-full bg-gray-200 lg:w-full opacity-20"></div>
        <>
          <form
            id="contact-form"
            name="gform"
            encType="text/plain"
            target="hidden_iframe"
            method="post"
          >
            <div className="">
              {!submitted && (
                <div className="flex flex-col min-[1400px]:justify-center items-center min-[1400px]:items-stretch">
                  <br />
                  <br />
                  <div className="flex-row flex space-x-10 ">
                    <div className="flex flex-col space-y-10 min-[1400px]:items-start items-center">
                      <div className="flex space-x-0 space-y-10 sm:space-y-0 sm:space-x-10 flex-col justify-start sm:flex-row">
                        <input
                          className="text-white placeholder-white text-[10px] p-4 pl-6 color-white rounded-sm w-96 bg-transparent border border-white"
                          type="text"
                          placeholder="NAME"
                          onChange={(e: {
                            target: { value: SetStateAction<string> };
                          }) => setNameValue(e.target.value)}
                        />
                        <input
                          className="text-white placeholder-white text-[10px] p-4 pl-6 color-white rounded-sm w-96 bg-transparent border border-white"
                          type="text"
                          placeholder="EMAIL"
                          onChange={(e: {
                            target: { value: SetStateAction<string> };
                          }) => setEmailValue(e.target.value)}
                        />
                      </div>
                      <div className="flex flex-col space-y-10 sm:space-y-0 sm:space-x-10 justify-start sm:flex-row">
                        <input
                          className="text-white placeholder-white text-[10px] p-4 pl-6 color-white rounded-sm w-96 bg-transparent border border-white"
                          type="text"
                          placeholder="SUBJECT"
                          onChange={(e: {
                            target: { value: SetStateAction<string> };
                          }) => setSubjectValue(e.target.value)}
                        />
                        <input
                          onChange={(e: {
                            target: { value: SetStateAction<string> };
                          }) => setMessageValue(e.target.value)}
                          className=" text-white placeholder-white text-[10px] p-4 pl-6 color-white rounded-sm w-96 resize-none bg-transparent border border-white"
                          placeholder="MESSAGE"
                        />
                      </div>
                      <Button
                        text="Send!"
                        id="submit-btn"
                        theme="light"
                        type="submit"
                        onClick={(e) => {
                          e.preventDefault();
                          if (validateEmail(emailValue) === false) {
                            alert("Please enter a valid email address");
                          } else if (emailValue && messageValue && nameValue) {
                            sendMessage();
                          } else {
                            alert("Please fill in all the fields!");
                          }
                        }}
                      />
                    </div>
                    <iframe
                      src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d7471623.16538881!2d45.822773!3d23.880628!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e2f03890d489399%3A0xba974d1c98e79fd5!2sRiyadh!5e0!3m2!1sen!2ssa!4v1712968305642!5m2!1sen!2ssa"
                      width="650"
                      id="map"
                      height="325"
                      className="rounded-3xl border-none hidden min-[1400px]:block"
                      loading="lazy"
                      referrerPolicy="no-referrer-when-downgrade"
                    ></iframe>
                  </div>
                </div>
              )}
              {submitted && (
                <div className="w-full flex items-center justify-center pt-10">
                  <br />
                  <br />
                  <div className=" border w-[90%] h-[30%] p-10 flex flex-col space-y-10 items-center justify-center">
                    <br />

                    <p className="text-2xl text-white">
                      Thank you <span>for contacting me</span>!
                    </p>
                    <p className="text-white text-center">
                      <br />
                      Thanks a bunch for filling it out. It means a lot to me,{" "}
                      just like you do! I really appreciate you giving me a
                      moment of your time today.
                    </p>
                    <br />
                    <br />
                    <br />
                  </div>
                </div>
              )}
            </div>
          </form>
        </>
      </div>
      <div className="flex justify-between p-10 sm:flex-row flex-row">
        <div className="flex space-x-10 uppercase text-[10px] text-white text-center">
          <div className="space-y-2 hidden sm:block">
            <p>copyright</p>
            <p>©️ {new Date().getFullYear()}</p>
          </div>
          <div className="space-y-2">
            <p>bibliographies</p>
            <a
              href="/bibliographies"
              className="flex justify-center items-center text-white"
              aria-label="link to bibliographies page"
            >
              <BsArrowUpRight size={25} />
            </a>
          </div>
        </div>
        <div className="flex space-x-10 uppercase text-[10px] text-white">
          <div className="space-y-2 md:block hidden">
            <p>local Time</p>
            <p>GMT{getTimezoneOffset()}</p>
          </div>

          {/* ! IMPLEMENT THIS INTO SANITY.IO */}
          <div className="space-y-2">
            <p>connect with me</p>
            <div className="space-x-5 capitalize flex flex-row">
              <a
                className="text-white sm:flex hidden"
                href="https://twitter.com/_neelbansal_"
              >
                Twitter
              </a>
              <a
                className="text-white"
                href="https://www.linkedin.com/in/neel-bansal-1ba81b245/"
              >
                LinkedIn
              </a>
              <a
                className="text-white sm:flex hidden"
                href="https://www.instagram.com/_neel_bansal_"
              >
                Instagram
              </a>
              <a className="text-white" href="https://github.com/NPX2218">
                Github
              </a>
              <a className="text-white" href="mailto:neelbansalx@gmail.com">
                Email
              </a>
              <a
                className="text-white sm:flex hidden"
                href="https://stackoverflow.com/users/23266351/neel-bansal"
              >
                StackOverflow
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

/////////////////////////////////////
// EXPORTING FOOTER
/////////////////////////////////////

export default Footer;
