/////////////////////////////////////
// IMPORTING LIBRARIES
/////////////////////////////////////

import React, { useEffect, useRef } from "react";
import LocomotiveScroll from "locomotive-scroll";
import "./art.css";
import Button from "../../components/Button";

/////////////////////////////////////
// COMPONENT: ART
/////////////////////////////////////

const Art = () => {
  const artRef = useRef<HTMLElement | null>(null);
  useEffect(() => {
    if (artRef) {
      let scroll = new LocomotiveScroll({
        el: artRef.current as HTMLElement,
        multiplier: 1,
        smooth: true,
        smartphone: {
          smooth: true,
        },
        getSpeed: true,
        getDirection: true,
        reloadOnContextChange: true,
      });
      const oddColumns = [...document.querySelectorAll(".column")].filter(
        (_, index) => index !== 1
      );
      // grid items (figure.column__item)
      //const gridItems = [...document.querySelectorAll(".column__item")];
      scroll.on("scroll", (obj: { scroll: { y: any } }) => {
        //const lastscroll = obj.scroll.y;
        oddColumns.forEach(
          (column: any) =>
            (column.style.transform = `translateY(${obj.scroll.y}px)`)
        );
      });
    }
  });

  return (
    <div>
      <div className="columns" ref={artRef as any}>
        <div className="column-wrap column-wrap--height">
          <div className="column">
            <figure className="column__item">
              <div className="column__item-imgwrap" data-pos="2">
                <div
                  className="column__item-img"
                  style={{
                    backgroundImage:
                      "url(https://raw.githubusercontent.com/codrops/ColumnScroll/main/src/img/2.jpg)",
                  }}
                ></div>
              </div>
              <figcaption className="column__item-caption">
                <span>Cyber Blue</span>
                <span>2011</span>
              </figcaption>
            </figure>
            <figure className="column__item">
              <div className="column__item-imgwrap" data-pos="5">
                <div
                  className="column__item-img"
                  style={{
                    backgroundImage:
                      "url(https://raw.githubusercontent.com/codrops/ColumnScroll/main/src/img/2.jpg)",
                  }}
                ></div>
              </div>
              <figcaption className="column__item-caption">
                <span>Gnostic Will</span>
                <span>2012</span>
              </figcaption>
            </figure>
            <figure className="column__item">
              <div className="column__item-imgwrap" data-pos="8">
                <div
                  className="column__item-img"
                  style={{
                    backgroundImage:
                      "url(https://raw.githubusercontent.com/codrops/ColumnScroll/main/src/img/2.jpg)",
                  }}
                ></div>
              </div>
              <figcaption className="column__item-caption">
                <span>French Kiss</span>
                <span>2013</span>
              </figcaption>
            </figure>
            <figure className="column__item">
              <div className="column__item-imgwrap" data-pos="11">
                <div
                  className="column__item-img"
                  style={{
                    backgroundImage:
                      "url(https://raw.githubusercontent.com/codrops/ColumnScroll/main/src/img/2.jpg)",
                  }}
                ></div>
              </div>
              <figcaption className="column__item-caption">
                <span>Half Life</span>
                <span>2014</span>
              </figcaption>
            </figure>
            <figure className="column__item">
              <div className="column__item-imgwrap" data-pos="14">
                <div
                  className="column__item-img"
                  style={{
                    backgroundImage:
                      "url(https://raw.githubusercontent.com/codrops/ColumnScroll/main/src/img/2.jpg)",
                  }}
                ></div>
              </div>
              <figcaption className="column__item-caption">
                <span>Love Boat</span>
                <span>2015</span>
              </figcaption>
            </figure>
            <figure className="column__item">
              <div className="column__item-imgwrap" data-pos="17">
                <div
                  className="column__item-img"
                  style={{
                    backgroundImage:
                      "url(https://raw.githubusercontent.com/codrops/ColumnScroll/main/src/img/2.jpg)",
                  }}
                ></div>
              </div>
              <figcaption className="column__item-caption">
                <span>Golden Ray</span>
                <span>2016</span>
              </figcaption>
            </figure>
            <figure className="column__item">
              <div className="column__item-imgwrap" data-pos="20">
                <div
                  className="column__item-img"
                  style={{
                    backgroundImage:
                      "url(https://raw.githubusercontent.com/codrops/ColumnScroll/main/src/img/2.jpg)",
                  }}
                ></div>
              </div>
              <figcaption className="column__item-caption">
                <span>Blame Game</span>
                <span>2017</span>
              </figcaption>
            </figure>
            <figure className="column__item">
              <div className="column__item-imgwrap" data-pos="23">
                <div
                  className="column__item-img"
                  style={{
                    backgroundImage:
                      "url(https://raw.githubusercontent.com/codrops/ColumnScroll/main/src/img/2.jpg)",
                  }}
                ></div>
              </div>
              <figcaption className="column__item-caption">
                <span>Lone Dust</span>
                <span>2018</span>
              </figcaption>
            </figure>
          </div>
        </div>
        <div className=" column-wrap">
          <div className="column" data-scroll-section>
            <figure className="column__item">
              <div className="column__item-imgwrap" data-pos="1">
                <div
                  className="column__item-img"
                  style={{
                    backgroundImage:
                      "url(https://raw.githubusercontent.com/codrops/ColumnScroll/main/src/img/2.jpg)",
                  }}
                ></div>
              </div>
              <figcaption className="column__item-caption">
                <span>Lucky Wood</span>
                <span>2019</span>
              </figcaption>
            </figure>
            <figure className="column__item">
              <div className="column__item-imgwrap" data-pos="4">
                <div
                  className="column__item-img"
                  style={{
                    backgroundImage:
                      "url(https://raw.githubusercontent.com/codrops/ColumnScroll/main/src/img/2.jpg)",
                  }}
                ></div>
              </div>
              <figcaption className="column__item-caption">
                <span>Good Earth</span>
                <span>2020</span>
              </figcaption>
            </figure>
            <figure className="column__item">
              <figcaption className="column__item-caption">
                <div className="w-[2vw] h-[2vh]">
                  <Button theme="dark" text="Back Home" link="/" />
                </div>
              </figcaption>
            </figure>
            <figure className="column__item">
              <div className="column__item-imgwrap" data-pos="10">
                <div
                  className="column__item-img"
                  style={{
                    backgroundImage:
                      "url(https://raw.githubusercontent.com/codrops/ColumnScroll/main/src/img/2.jpg)",
                  }}
                ></div>
              </div>
              <figcaption className="column__item-caption">
                <span>Nonage Line</span>
                <span>2009</span>
              </figcaption>
            </figure>
            <figure className="column__item">
              <div className="column__item-imgwrap" data-pos="13">
                <div
                  className="column__item-img"
                  style={{
                    backgroundImage:
                      "url(https://raw.githubusercontent.com/codrops/ColumnScroll/main/src/img/2.jpg)",
                  }}
                ></div>
              </div>
              <figcaption className="column__item-caption">
                <span>Blue Hell</span>
                <span>2010</span>
              </figcaption>
            </figure>
            <figure className="column__item">
              <div className="column__item-imgwrap" data-pos="16">
                <div
                  className="column__item-img"
                  style={{
                    backgroundImage:
                      "url(https://raw.githubusercontent.com/codrops/ColumnScroll/main/src/img/2.jpg)",
                  }}
                ></div>
              </div>
              <figcaption className="column__item-caption">
                <span>Cold Blood</span>
                <span>2011</span>
              </figcaption>
            </figure>
            <figure className="column__item">
              <div className="column__item-imgwrap" data-pos="19">
                <div
                  className="column__item-img"
                  style={{
                    backgroundImage:
                      "url(https://raw.githubusercontent.com/codrops/ColumnScroll/main/src/img/2.jpg)",
                  }}
                ></div>
              </div>
              <figcaption className="column__item-caption">
                <span>Tulip Heat</span>
                <span>2012</span>
              </figcaption>
            </figure>
            <figure className="column__item">
              <div className="column__item-imgwrap" data-pos="22">
                <div
                  className="column__item-img"
                  style={{
                    backgroundImage:
                      "url(https://raw.githubusercontent.com/codrops/ColumnScroll/main/src/img/2.jpg)",
                  }}
                ></div>
              </div>
              <figcaption className="column__item-caption">
                <span>Red Wrath</span>
                <span>2013</span>
              </figcaption>
            </figure>
          </div>
        </div>
        <div className="column-wrap column-wrap--height">
          <div className="column">
            <figure className="column__item">
              <div className="column__item-imgwrap" data-pos="3">
                <div
                  className="column__item-img"
                  style={{
                    backgroundImage:
                      "url(https://raw.githubusercontent.com/codrops/ColumnScroll/main/src/img/2.jpg)",
                  }}
                ></div>
              </div>
              <figcaption className="column__item-caption">
                <span>Bold Human</span>
                <span>2014</span>
              </figcaption>
            </figure>
            <figure className="column__item">
              <div className="column__item-imgwrap" data-pos="6">
                <div
                  className="column__item-img"
                  style={{
                    backgroundImage:
                      "url(https://raw.githubusercontent.com/codrops/ColumnScroll/main/src/img/2.jpg)",
                  }}
                ></div>
              </div>
              <figcaption className="column__item-caption">
                <span>Loyal Royal</span>
                <span>2015</span>
              </figcaption>
            </figure>
            <figure className="column__item">
              <div className="column__item-imgwrap" data-pos="9">
                <div
                  className="column__item-img"
                  style={{
                    backgroundImage:
                      "url(https://raw.githubusercontent.com/codrops/ColumnScroll/main/src/img/2.jpg)",
                  }}
                ></div>
              </div>
              <figcaption className="column__item-caption">
                <span>Lone Cone</span>
                <span>2016</span>
              </figcaption>
            </figure>
            <figure className="column__item">
              <div className="column__item-imgwrap" data-pos="12">
                <div
                  className="column__item-img"
                  style={{
                    backgroundImage:
                      "url(https://raw.githubusercontent.com/codrops/ColumnScroll/main/src/img/2.jpg)",
                  }}
                ></div>
              </div>
              <figcaption className="column__item-caption">
                <span>Dutch Green</span>
                <span>2017</span>
              </figcaption>
            </figure>
            <figure className="column__item">
              <div className="column__item-imgwrap" data-pos="15">
                <div
                  className="column__item-img"
                  style={{
                    backgroundImage:
                      "url(https://raw.githubusercontent.com/codrops/ColumnScroll/main/src/img/2.jpg)",
                  }}
                ></div>
              </div>
              <figcaption className="column__item-caption">
                <span>Valley Hill</span>
                <span>2018</span>
              </figcaption>
            </figure>
            <figure className="column__item">
              <div className="column__item-imgwrap" data-pos="18">
                <div
                  className="column__item-img"
                  style={{
                    backgroundImage:
                      "url(https://raw.githubusercontent.com/codrops/ColumnScroll/main/src/img/2.jpg)",
                  }}
                ></div>
              </div>
              <figcaption className="column__item-caption">
                <span>Kale Hale</span>
                <span>2019</span>
              </figcaption>
            </figure>
            <figure className="column__item">
              <div className="column__item-imgwrap" data-pos="21">
                <div
                  className="column__item-img"
                  style={{
                    backgroundImage:
                      "url(https://raw.githubusercontent.com/codrops/ColumnScroll/main/src/img/2.jpg)",
                  }}
                ></div>
              </div>
              <figcaption className="column__item-caption">
                <span>Fake Cake</span>
                <span>2020</span>
              </figcaption>
            </figure>
            <figure className="column__item">
              <div className="column__item-imgwrap" data-pos="24">
                <div
                  className="column__item-img"
                  style={{
                    backgroundImage:
                      "url(https://raw.githubusercontent.com/codrops/ColumnScroll/main/src/img/2.jpg)",
                  }}
                ></div>
              </div>
              <figcaption className="column__item-caption">
                <span>Book Belly</span>
                <span>2021</span>
              </figcaption>
            </figure>
          </div>
        </div>
      </div>
    </div>
  );
};

/////////////////////////////////////
// EXPORTING ART
/////////////////////////////////////

export default Art;
