import { UIDesign, Project } from "../typings";
export const getFeaturedProjects = (): Array<Object> => {
  /**
   * Returns an array of project objects
   *
   * @return {Array<Object>} An array of project objects with title, tags, and sortingTags properties
   */

  const projects = [
    {
      title: "Pathfinding Visualizer",
      tags: ["Algorithms", "State"],
      sortingTags: ["fun_things"],
    },
    {
      title: "Discord Bot",
      tags: ["Algorithms", "State"],
      sortingTags: ["fun_things"],
    },
    {
      title: "e1",
      tags: ["Algorithms", "State"],
      sortingTags: ["fun_things"],
    },
    {
      title: "e2",
      tags: ["Algorithms", "State"],
      sortingTags: ["fun_things"],
    },
    {
      title: "e3",
      tags: ["Algorithms", "State"],
      sortingTags: ["fun_things"],
    },
    {
      title: "e4",
      tags: ["Algorithms", "State"],
      sortingTags: ["fun_things"],
    },
  ];
  return projects;
};

export const getBibliographies = () => {
  /**
   * Returns an array of bibliography objects
   *
   * @return {Array<Object>} An array of bibliography objects with title, tags, and sortingTags properties
   */

  const bibliographies = [
    {
      name: "Framer Motion",
      link: "https://www.framer.com/motion/",
      reason: "Smooth Animations",
    },
    {
      name: "React Icons",
      link: "https://react-icons.github.io/",
      reason: "Representative Icons",
    },
    {
      name: "Web Yule Log",
      link: "https://webyulelog.com/",
      reason: "Portfolio Inspiration",
    },
    {
      name: "Locomotive Scroll",
      link: "https://github.com/locomotivemtl/locomotive-scroll",
      reason: "Smooth Scrolling",
    },
    {
      name: "React Router",
      link: "https://reactrouter.com/en/main",
      reason: "Page Navigation",
    },
    {
      name: "Sahil Bansal's Portfolio",
      link: "https://sahilbansal2701.github.io/",
      reason: "Portfolio Inspiration",
    },
    {
      name: "Zachary Espiritu's Portfolio",
      link: "https://zacharyespiritu.com/",
      reason: "Portfolio Inspiration",
    },
    {
      name: "CesXC Portfolio",
      link: "https://dribbble.com/shots/19725727-CesXC-Portfolio-Website",
      reason: "Portfolio Inspiration",
    },
    {
      name: "Figma",
      link: "https://www.figma.com/",
      reason: "UI Design",
    },
    {
      name: "Tailwind CSS",
      link: "https://tailwindcss.com/",
      reason: "CSS Styles",
    },
  ];
  return bibliographies;
};

export const getProjects = (): Array<Project> => {
  /**
   * Returns an array of project objects
   *
   * @return {Array<Project>} An array of project objects with title, tags, and sortingTags properties
   */
  const projects: Array<Project> = [
    {
      title: "Pathfinding Visualizer",
      tags: ["Algorithms", "State"],
      image: "",
      github: "",
      themeColor: "",
      year: 2022,
      rating: 5,
      sortingTags: ["fun_things"],
    },
    {
      title: "Pathfinding Visualizer",
      tags: ["Algorithms", "State"],
      image: "",
      github: "",
      themeColor: "",

      year: 2022,
      rating: 5,
      sortingTags: ["fun_things"],
    },
    {
      title: "Pathfinding Visualizer",
      tags: ["Algorithms", "State"],
      image: "",
      github: "",
      year: 2022,
      themeColor: "",

      rating: 5,
      sortingTags: ["fun_things"],
    },
    {
      title: "Pathfinding Visualizer",
      tags: ["Algorithms", "State"],
      image: "",
      github: "",
      themeColor: "",
      year: 2022,
      rating: 5,
      sortingTags: ["fun_things"],
    },
    {
      title: "Pathfinding Visualizer",
      tags: ["Algorithms", "State"],
      image: "",
      github: "",
      year: 2022,
      themeColor: "",

      rating: 5,
      sortingTags: ["fun_things"],
    },
  ];
  return projects;
};

export const getUIDesigns = (): Array<UIDesign> => {
  const UIDesigns: Array<UIDesign> = [
    {
      title: "Pathfinding Visualizer",
      description:
        "Our bank UI was designed with the user in mind. The clean and modern interface makes it easy for customers to manage their finances, view account information, and make transactions. The responsive design ensures that the platform can be accessed and used seamlessly on any device. In addition, we've included a variety of features to enhance the user experience, such as a customizable dashboard and detailed transaction history. We believe that our bank UI is the perfect blend of functionality and aesthetics, and we're confident that it will provide a superior banking experience for our users.",
      image: "",
      singlePreview: "",
      themeColor: "",
      year: 2022,
      rating: 5,
    },
    {
      title: "Pathfinding Visualizer",
      description: "hello",
      image: "",
      singlePreview: "",
      themeColor: "",
      year: 2022,
      rating: 5,
    },
  ];
  return UIDesigns;
};
