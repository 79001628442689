/////////////////////////////////////
// IMPORTING LIBRARIES
/////////////////////////////////////

import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import Router from "./router/Router";
import { BrowserRouter } from "react-router-dom";
import { LoadingProvider } from "./helpers/LoadingContext";
import { DataProvider } from "./api/DataContext";

/////////////////////////////////////
// RENDER: DOM
/////////////////////////////////////

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <BrowserRouter>
    <DataProvider>
      <LoadingProvider>
        <Router />
      </LoadingProvider>
    </DataProvider>
  </BrowserRouter>
);
