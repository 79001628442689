import React from "react";
import { BsArrowDown } from "@react-icons/all-files/bs/BsArrowDown";
import "./ScrollDown.css";

/**
 * ScrollDown component returns a div with a series of 4 horizontal lines, text and
 * an arrow pointing downwards. Meant to signal the user to scroll down.
 *
 * @return {JSX.Element} Returns a div with a series of 4 horizontal lines, text and
 * an arrow pointing downwards.
 */
const ScrollDown = () => {
  return (
    <div className="mt-10 space-y-6 items-end flex flex-col justify-center">
      <div className="w-[100px] h-1 bg-white animate-scroll-down-1"></div>
      <div className="w-[75px] h-1 bg-white animate-scroll-down-2"></div>
      <div className="w-[50px] h-1 bg-white animate-scroll-down-3"></div>
      <div className="w-[25px] h-1 bg-white animate-scroll-down-4"></div>
      <p className="text-sm uppercase transform [writing-mode:vertical-lr] flex ">
        SCROLL DOWN
      </p>
      <BsArrowDown size={30} />
    </div>
  );
};

export default ScrollDown;
