/////////////////////////////////////
// IMPORTING LIBRARIES
/////////////////////////////////////

import React, { useState } from "react";
import profileImage from "../../../assets/images/profile-image.svg";
import Button from "../../../components/Button";
import Tags from "../../../components/Tags";
import { motion } from "framer-motion";
import Resume from "../../../assets/pdfs/resume.pdf";

/////////////////////////////////////
// COMPONENT: ABOUT
/////////////////////////////////////

const About: React.FC = () => {
  const container = {
    visible: {
      transition: {
        staggerChildren: 0.025,
      },
    },
  };

  return (
    <div id="about" className=" max-w-6xl lg:p-10 md:p-10 m-10 lg:mx-auto">
      <br />
      <br />
      <div className="flex flex-row space-x-10">
        <div className="sm:flex sm:flex-col w-full sm:justify-center ">
          <h2 className="uppercase font-bold">
            <Tags type="h2" text="about me" />
          </h2>
          <br />
          <p className="text-sm lg:w-full md:w-89"></p>
          <br />
          <div>
            <div className="container">
              <p>
                Hey there! <br /> I'm Neel, a Front-End Developer with a knack
                for creating visually stunning projects. With 3 years of
                experience under my belt, I've had the chance to work on some
                pretty awesome projects. Check them out in my featured work
                section! I'm always on the lookout for new adventures and
                opportunities to sharpen my skills and up my game. <br /> When
                I'm not coding up a storm, you can find me pounding the pavement
                while running, getting artsy with my drawings, jamming out to
                music, or pumping iron at the gym. All of which I believe, helps
                me bring fresh ideas and creativity to my work. Want to see some
                of my past projects and get a feel for my skills? Head on over
                to my projects page. And if you have any questions or want to
                chat about a potential project, don't be shy, hit me up! <br />{" "}
                Thanks for stopping by my portfolio! <br /> Neel Bansal
              </p>
              <div className="sm:hidden block">
                <br />
                <br />
              </div>
            </div>
          </div>

          <div className="w-full flex flex-col items-center sm:flex-col sm:items-center md:items-start lg:items-start">
            <img
              alt="personal image"
              src={profileImage}
              width="390px"
              height="500px"
              className="md:hidden block m-0"
            />
            <br />
            <Button
              target="_blank"
              theme="dark"
              text="CV Link Download"
              link={Resume}
            />
          </div>
          <br />
        </div>

        <div className="md:flex w-1/2 justify-center items-start hidden">
          <img
            alt="personal image"
            src={profileImage}
            width="300px"
            height="400px"
          />
        </div>
      </div>
    </div>
  );
};

/////////////////////////////////////
// EXPORTING ABOUT
/////////////////////////////////////

export default About;
