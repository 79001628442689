/////////////////////////////////////
// FUNCTION: GET TIMEZONE OFFSET
/////////////////////////////////////

export const getTimezoneOffset = (): string => {
  function z(n: any) {
    return (n < 10 ? "0" : "") + n;
  }
  var offset = new Date().getTimezoneOffset();
  var sign = offset < 0 ? "+" : "-";
  offset = Math.abs(offset);
  return sign + z((offset / 60) | 0) + z(offset % 60);
};

/////////////////////////////////////
// FUNCTION: VALIDATE EMAIL
/////////////////////////////////////

export const validateEmail = (email: string): boolean => {
  var re = /\S+@\S+\.\S+/;
  return re.test(email);
};

/////////////////////////////////////
// FUNCTION: ISPHONE
/////////////////////////////////////

export const isPhone = (): boolean => {
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  const isLaptop =
    !isMobile &&
    navigator.maxTouchPoints === 0 &&
    (navigator.platform.indexOf("Mac") !== -1 ||
      navigator.platform.indexOf("Win") === -1);
  return isLaptop || isMobile;
};
