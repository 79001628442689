/////////////////////////////////////
// IMPORTING LIBRARIES
/////////////////////////////////////

import React, { useContext, createContext, useState, ReactNode } from "react";

/////////////////////////////////////
// INTERFACE: LOADINGCONTEXTTYPE
/////////////////////////////////////

interface LoadingContextType {
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  loading: boolean;
}

const LoadingContext = createContext<LoadingContextType | undefined>(undefined);

/////////////////////////////////////
// FUNCTION: USELOADING
/////////////////////////////////////

export function useLoading() {
  const context = useContext(LoadingContext);
  if (context === undefined) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
}

/////////////////////////////////////
// COMPONENT: LOADINGPROVIDER
/////////////////////////////////////

export const LoadingProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [loading, setLoading] = useState<boolean>(true);

  const value: LoadingContextType = {
    loading,
    setLoading,
  };

  return (
    <LoadingContext.Provider value={value}>{children}</LoadingContext.Provider>
  );
};
