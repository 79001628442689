/////////////////////////////////////
// IMPORTING LIBRARIES
/////////////////////////////////////

import React, { useEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";

import { AnimatePresence, motion } from "framer-motion";

import ErrorPage from "../pages/404/404";
import App from "../pages/Home/App";
import Work from "../pages/Work";
import Bibliographies from "../pages/Bibliographies/Bibliographies";
import Projects from "../pages/Projects/Projects";
import UIGallery from "../pages/UIGallery/UIGallery";
import Art from "../pages/Art/Art";
import { useLoading } from "../helpers/LoadingContext";
import LocomotiveScroll from "locomotive-scroll";
import { isPhone } from "../helpers/helper";

/////////////////////////////////////
// COMPONENT: ROUTER
/////////////////////////////////////
const Router: React.FC = (): JSX.Element => {
  /**
   * Renders a Router component that switches between different Routes based on the path in the URL.
   * Sets the document title to "Why did you leave :(" when the window loses focus.
   * Sets the title of the document to "Neel Bansal | Portfolio" when the window gains focus.
   * Initializes a LocomotiveScroll instance.
   *
   * @return {JSX.Element} The JSX element to be rendered
   */

  const { loading } = useLoading();

  const handleBlur = () => {
    document.title = "Why did you leave :(";
  };

  const handleFocus = (event: Event) => {
    document.title = "Neel Bansal | Portfolio";
  };

  /*useEffect(() => {
    if (!loading && scrollRef) {
      import("locomotive-scroll").then((locomotiveModule) => {
        const ScrollEl = scrollRef.current as any;
        const newScroll = new locomotiveModule.default({
          el: ScrollEl,
          multiplier: 1,
          smooth: true,
          smartphone: {
            smooth: false,
          },
          getSpeed: true,
          getDirection: true,
          reloadOnContextChange: true,
        });

        setScrollPass(newScroll);

        new ResizeObserver(() => newScroll.update()).observe(ScrollEl);

        return () => newScroll.destroy();
      });
    }
  }, [loading]);

  useEffect(() => {
    if (!loading) {
      window.addEventListener("blur", handleBlur);
      window.addEventListener("focus", handleFocus);

      return () => {
        window.removeEventListener("blur", handleBlur);
        window.removeEventListener("focus", handleFocus);
      };
    }
  }, [loading]);*/

  useEffect(() => {
    if (!loading) {
      new LocomotiveScroll({
        el: document.querySelector("[data-scroll-container]") as HTMLElement,
        multiplier: isPhone() === true ? 1.4 : 0.75,
        smooth: true,
        smartphone: {
          smooth: true,
        },
        getSpeed: true,
        getDirection: true,
        reloadOnContextChange: true,
      });

      window.addEventListener("blur", handleBlur);
      window.addEventListener("focus", handleFocus);

      return () => {
        window.removeEventListener("blur", handleBlur);
        window.removeEventListener("focus", handleFocus);
      };
    }
  }, [loading]);

  const location = useLocation();

  return (
    <AnimatePresence mode="wait">
      <Routes location={location} key={location.pathname}>
        <Route
          path="/"
          element={
            <motion.div data-scroll-container>
              <App />
            </motion.div>
          }
        />
        <Route
          path="/*"
          element={
            <motion.div data-scroll-container>
              <motion.div
                initial={{ y: 100 }}
                animate={{ y: "-200%" }}
                transition={{
                  duration: 1,
                  ease: "easeOut",
                }}
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  right: 0,
                  height: "50vh",
                  backgroundColor: "#1C1D20",
                }}
              />
              <motion.div
                initial={{ y: 0 }}
                animate={{ y: "-200%" }}
                transition={{ duration: 1, ease: "easeOut" }}
                style={{
                  position: "absolute",
                  top: "50vh",
                  left: 0,
                  right: 0,
                  height: "50vh",
                  backgroundColor: "#1c1d20",
                }}
              />
              <motion.div
                initial={{ y: 0 }}
                animate={{ y: "-270%" }}
                transition={{
                  duration: 2,
                  ease: "easeOut",
                }}
                style={{
                  position: "absolute",
                  top: "75vh",
                  left: 0,
                  right: 0,
                  height: "50vh",
                  backgroundColor: "#37393d",
                }}
              />

              <motion.div
                initial={{ y: 0 }}
                animate={{ y: "-300%" }}
                transition={{
                  duration: 1.75,
                  ease: "easeOut",
                }}
                style={{
                  position: "absolute",
                  top: "100vh",
                  left: 0,
                  right: 0,
                  height: "50vh",
                  backgroundColor: "white",
                }}
              />

              <ErrorPage />
            </motion.div>
          }
        />
        <Route
          path="/work"
          element={
            <div data-scroll-container>
              <Work />
            </div>
          }
        />
        <Route
          path="/work_cases"
          element={
            <div data-scroll-container>
              <Projects />
            </div>
          }
        />
        <Route
          path="/bibliographies"
          element={
            <div data-scroll-container>
              <Bibliographies />
            </div>
          }
        />
        <Route
          path="/ui-designs"
          element={
            <div data-scroll-container>
              <UIGallery />
            </div>
          }
        />
        <Route
          path="/art"
          element={
            <div data-scroll-container>
              <Art />
            </div>
          }
        />
      </Routes>
    </AnimatePresence>
  );
};

/////////////////////////////////////
// EXPORTING ROUTER
/////////////////////////////////////

export default Router;
