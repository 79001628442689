/////////////////////////////////////
// IMPORTING LIBRARIES
/////////////////////////////////////

import React from "react";

/////////////////////////////////////
// COMPONENT: UIGALLERY
/////////////////////////////////////

const UIGallery = () => {
  return (
    <div className="flex flex-wrap">
      <div className="bg-red-500 w-[250px] h-[250px] flex-grow">01</div>
      <div className="bg-blue-500 w-[250px] h-[250px] flex-grow">02</div>
      <div className="bg-red-500 w-[250px] h-[250px] flex-grow">03</div>
      <div className="bg-blue-500 w-[250px] h-[250px] flex-grow">03</div>
      <div className="bg-red-500 w-[250px] h-[250px] flex-grow">03</div>
      <div className="bg-blue-500 w-[250px] h-[250px] flex-grow">03</div>
      <div className="bg-red-500 w-[250px] h-[250px] flex-grow">03</div>
      <div className="bg-red-500 w-[250px] h-[250px] flex-grow">01</div>
      <div className="bg-blue-500 w-[250px] h-[250px] flex-grow">02</div>
      <div className="bg-red-500 w-[250px] h-[250px] flex-grow">03</div>
      <div className="bg-blue-500 w-[250px] h-[250px] flex-grow">03</div>
      <div className="bg-red-500 w-[250px] h-[250px] flex-grow">03</div>
      <div className="bg-blue-500 w-[250px] h-[250px] flex-grow">03</div>
      <div className="bg-red-500 w-[250px] h-[250px] flex-grow">03</div>
      <div className="bg-blue-500 w-[250px] h-[250px] flex-grow">03</div>
      <div className="bg-red-500 w-[250px] h-[250px] flex-grow">03</div>
      <div className="bg-red-500 w-[250px] h-[250px] flex-grow">01</div>
      <div className="bg-blue-500 w-[250px] h-[250px] flex-grow">02</div>
      <div className="bg-red-500 w-[250px] h-[250px] flex-grow">03</div>
      <div className="bg-blue-500 w-[250px] h-[250px] flex-grow">03</div>
      <div className="bg-red-500 w-[250px] h-[250px] flex-grow">03</div>
      <div className="bg-blue-500 w-[250px] h-[250px] flex-grow">03</div>
      <div className="bg-blue-500 w-[250px] h-[250px] flex-grow">03</div>
      <div className="bg-red-500 w-[250px] h-[250px] flex-grow">03</div>
      <div className="bg-blue-500 w-[250px] h-[250px] flex-grow">03</div>
      <div className="bg-blue-500 w-[250px] h-[250px] flex-grow">03</div>{" "}
      <div className="bg-blue-500 w-[250px] h-[250px] flex-grow">03</div>{" "}
      <div className="bg-blue-500 w-[250px] h-[250px] flex-grow">03</div>{" "}
      <div className="bg-blue-500 w-[250px] h-[250px] flex-grow">03</div>{" "}
      <div className="bg-blue-500 w-[250px] h-[250px] flex-grow">03</div>
      <div className="bg-blue-500 w-[250px] h-[250px] flex-grow">03</div>{" "}
      <div className="bg-blue-500 w-[250px] h-[250px] flex-grow">03</div>{" "}
      <div className="bg-blue-500 w-[250px] h-[250px] flex-grow">03</div>{" "}
      <div className="bg-blue-500 w-[250px] h-[250px] flex-grow">03</div>{" "}
      <div className="bg-blue-500 w-[250px] h-[250px] flex-grow">03</div>{" "}
      <div className="bg-blue-500 w-[250px] h-[250px] flex-grow">03</div>{" "}
      <div className="bg-blue-500 w-[250px] h-[250px] flex-grow">03</div>{" "}
      <div className="bg-blue-500 w-[250px] h-[250px] flex-grow">03</div>{" "}
      <div className="bg-blue-500 w-[250px] h-[250px] flex-grow">03</div>
      <div className="bg-blue-500 w-[250px] h-[250px] flex-grow">03</div>
    </div>
  );
};

/////////////////////////////////////
// EXPORTING UIGALLERY
/////////////////////////////////////

export default UIGallery;
