/////////////////////////////////////
// IMPORTING LIBRARIES
/////////////////////////////////////

import React, { useState } from "react";
import { useData } from "../../../api/DataContext";
import { urlFor } from "../../../client";
import { Modal } from "@mui/material";
import { IoIosClose } from "react-icons/io";

/////////////////////////////////////
// FUNCTION: FEATUREDCERTIFICATES
/////////////////////////////////////

const FeaturedCertificates: React.FC = () => {
  const { certificatesData } = useData();
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [modalImage, setModalImage] = useState<null | string>(null);

  return (
    <section className=" flex justify-center items-center">
      <br />
      <br />
      <div className="flex space-y-2 flex-col">
        <div
          data-scroll
          data-scroll-speed="-2"
          data-scroll-direction="horizontal"
          className="flex w-max flex-row space-x-10"
        >
          {certificatesData &&
            certificatesData
              ?.slice(0, 7)
              .map((certificate: any) => (
                <Certificate
                  setModalOpen={setModalOpen}
                  key={certificate.title}
                  setModalImage={setModalImage}
                  image={certificate.mainImage}
                />
              ))}
        </div>
        <div
          data-scroll
          data-scroll-speed="2"
          data-scroll-direction="horizontal"
          className=" flex flex-row space-x-10"
        >
          {certificatesData &&
            certificatesData
              ?.slice(6, 17)
              .map((certificate: any) => (
                <Certificate
                  setModalOpen={setModalOpen}
                  key={certificate.title}
                  setModalImage={setModalImage}
                  image={certificate.mainImage}
                />
              ))}
        </div>
      </div>

      <Modal
        onClick={() => setModalOpen(false)}
        open={modalOpen}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div>
          <div className="text-[#1D1D1D] right-0 absolute">
            <IoIosClose size={100} />
          </div>
          <img
            alt="certificate image"
            width="100%"
            height="100%"
            style={{
              maxWidth: "100vw",
              maxHeight: "100vh",
              objectFit: "contain",
            }}
            src={modalImage ? urlFor(modalImage).url() : ""}
          />
        </div>
      </Modal>
    </section>
  );
};

/////////////////////////////////////
// COMPONENT: CERTIFICATE
/////////////////////////////////////

const Certificate = ({ image, setModalOpen, setModalImage }: any) => {
  return (
    <div
      onClick={() => {
        setModalImage(image);
        setModalOpen(true);
      }}
      className="w-80 h-56 flex items-center justify-center p-5"
      style={{ backgroundColor: "#1D1D1D" }}
    >
      <img
        width="280"
        height="200"
        alt="single certificate image"
        src={image && urlFor(image).width(280).height(200).url()}
      />
    </div>
  );
};

/////////////////////////////////////
// EXPORTING FEATUREDCERTIFICATES
/////////////////////////////////////

export default FeaturedCertificates;
