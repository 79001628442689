/////////////////////////////////////
// IMPORTING LIBRARIES
/////////////////////////////////////

import React from "react";
import RotatingPlay from "./RotatingPlay";
import waterMark from "../../../assets/images/water_marks.svg";
import { FiArrowDownRight } from "@react-icons/all-files/fi/FiArrowDownRight";
import ScrollDown from "./ScrollDown/ScrollDown";

/////////////////////////////////////
// COMPONENT: INTRODUCTION
/////////////////////////////////////

const Introduction: React.FC = (): JSX.Element => {
  return (
    <section data-speed={2} style={{ backgroundColor: "#1C1D20" }}>
      <div className="justify-center flex flex-col space-x-0 sm:space-x-10 overflow-hidden">
        <h1 className=" text-white flex flex-col text-9xl">
          <div className="wrapper max-w-full overflow-hidden">
            <div
              style={{ animation: "marquee 12s linear infinite" }}
              className=" whitespace-nowrap overflow-hidden inline-block"
            >
              <h1
                data-scroll
                data-scroll-direction="horizontal"
                data-scroll-speed="4"
              >
                Neel Bansal -Neel Bansal - Neel Bansal - Neel Bansal - Neel
                Bansal - Neel Bansal - Neel Bansal - Neel Bansal
              </h1>
            </div>
          </div>
        </h1>
        <div className="flex flex-row text-white text-left">
          <div className="items-center min-[800px]:flex hidden">
            <RotatingPlay />
          </div>

          <img
            className="lg:w-5/6 md:w-[580px] md:h-[] sm:w-[500px] w-full h-full"
            src={waterMark}
            alt="water mark"
          ></img>
          <div
            data-scroll
            className="md:hidden flex  xl:flex 2xl:flex sm:hidden ml-10 flex-col w-2/4 sm:w-1/3 justify-center mr-10 text-xl "
          >
            <FiArrowDownRight size={20} />

            <span className="ml-10">Web Designer</span>
            <span className="ml-10">Student</span>
            <span className="ml-10">Developer</span>
          </div>
          <div className="w-1/3 mr-10 items-end flex-col hidden sm:flex">
            <ScrollDown />
            <br />
          </div>
        </div>
      </div>
    </section>
  );
};

/////////////////////////////////////
// EXPORTING INTRODUCION
/////////////////////////////////////

export default Introduction;
