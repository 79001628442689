/////////////////////////////////////
// IMPORTING LIBRARIES
/////////////////////////////////////

import React, { useEffect, useState } from "react";
import Button from "../../../components/Button";
import Tags from "../../../components/Tags";
import Work from "./Work";
import { v4 as uuidv4 } from "uuid";
import { useData } from "../../../api/DataContext";
import { useLoading } from "../../../helpers/LoadingContext";

/////////////////////////////////////
// COMPONENT: FEATUREDWORK
/////////////////////////////////////

const FeaturedWork = ({ scroll }: any) => {
  const filters: Array<string> = [
    "all",
    "fun_things",
    "case_studies",
    "games",
    "design",
    "academic",
    "software",
  ];

  const [currentFilter, setCurrentFilter] = useState<string>("all");

  return (
    <div className="max-w-5xl m-10 lg:mx-auto">
      <br />
      <br />
      <div className="flex flex-row space-x-10">
        <div className="sm:flex sm:flex-col w-full sm:justify-center ">
          <h2 className=" ml-20 uppercase font-bold">
            <Tags type="h2" text="featured works" />
          </h2>
          <p className="md:hidden  lg:flex ml-20 uppercase text-sm pt-1 pb-2 sm:hidden flex flex-row">
            <span className="hidden sm:block">
              {"> Neel.work.sort({(w): WORK =="}
            </span>
            <span className="hidden md:flex justify-center flex-row space-x-2 ml-2">
              {filters.map((name) => {
                return (
                  <a
                    className={
                      name === currentFilter
                        ? " cursor-pointer hover:text-gray-600 font-extrabold underline"
                        : " cursor-pointer hover:text-gray-600 font-semibold"
                    }
                    key={uuidv4()}
                    onClick={() => {
                      setCurrentFilter(name);
                    }}
                  >
                    <span
                      className={name === currentFilter ? "font-bold " : ""}
                    >
                      {name}
                    </span>
                  </a>
                );
              })}
            </span>
            <span className="hidden sm:block"> {"})"}</span>
          </p>
          <div>
            <ul
              className="pill-sroll mb-5 overflow-x-scroll pill-scroll flex-nowrap space-x-2 lg:hidden flex list-none pl-0 flex-row"
              role="tablist"
            >
              {filters.map((name) => {
                return (
                  <li key={uuidv4()} role="tab">
                    <a
                      className=" my-2 block rounded-3xl  px-7 pt-4 pb-3.5 text-xs font-medium uppercase leading-tight text-white data-[te-nav-active]:!bg-primary-100 data-[te-nav-active]:text-primary-700 bg-[#1C1D20] dark:text-white dark:data-[te-nav-active]:text-primary-700 md:mr-4"
                      onClick={() => {
                        setCurrentFilter(name);
                      }}
                    >
                      {name}
                    </a>
                  </li>
                );
              })}
            </ul>
          </div>
          <div className="w-full flex items-center justify-center flex-col">
            <div className="w-5/6">
              <Work filter={currentFilter} />
            </div>
            <br />

            <Button theme="dark" text="More works" link="/work" />
          </div>
        </div>
      </div>
    </div>
  );
};

/////////////////////////////////////
// EXPORTING FEATUREDWORK
/////////////////////////////////////

export default FeaturedWork;
