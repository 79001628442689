/////////////////////////////////////
// IMPORTING LIBRARIES
/////////////////////////////////////

import { useEffect, useRef } from "react";
import { gsap } from "gsap";
import Typewriter from "typewriter-effect";
import { useData } from "../api/DataContext";

/////////////////////////////////////
// IMPORTING LIBRARIES
/////////////////////////////////////

export default function MoreProjects() {
  const { projectsData } = useData();
  const currentYear = new Date().getFullYear();

  const yearRef = useRef<HTMLSpanElement>(null);

  useEffect(() => {
    if (yearRef.current) {
      gsap.to(yearRef.current, {
        rotationY: -360,
        duration: 3,
        ease: "power2.inOut",
        repeat: -1,
      });

      gsap.from(yearRef.current.cloneNode(true), {
        y: "100%",
        duration: 1,
        delay: 3,
        ease: "power2.out",
        onComplete: () => {
          // Animation completed
        },
      });
    }
  }, []);

  return (
    <div className="w-[100vw] h-[100vh] overflow-hidden">
      <div className="px-20 pt-20 justify-between flex flex-col sm:flex-row">
        <div>
          <a href="/" className="m-0 text-[26px] leading-[38px]">
            NEEL
            <br />
            PORTFOLIO
          </a>
        </div>
        <div>
          <div className="flex items-start w-[207px] h-[26px] gap-[38px] pt-5 sm:pt-0">
            <a href="/#about" className="m-0 w-[41px] text-[15px] h-[26px]">
              About
            </a>
            <a href="/#work" className="w-9 m-0 text-[15px] h-[26px]">
              Work
            </a>
            <a href="/#contact" className="m-0 w-[54px] text-[15px] h-[26px]">
              Contact
            </a>
          </div>
        </div>
      </div>
      <div className="flex flex-col flex-wrap bg-white text-black text-left p-[37px] pl-[58px]">
        <div className="w-full h-full flex flex-row">
          <div className="w-0 sm:w-4/6 h-100vh flex items-center justify-center">
            <div className="sm:flex hidden w-4/6 h-full text-center justify-center items-center">
              <p className="flex flex-row text-[20px] sm:text-[12vw] min-[1135px]:[writing-mode:horizontal-tb] [writing-mode:vertical-rl] ">
                <Typewriter
                  onInit={(typewriter) => {
                    typewriter.typeString("WORKS").start();
                  }}
                />
              </p>
            </div>
          </div>
          <div className="w-11/12 md:w-2/6 h-full flex">
            <div className="flex w-full justify-center items-center sm:items-end sm:justify-end space-y-6 flex-col">
              <div className="space-y-6">
                <div className="h-[3px] w-[100vw] sm:w-[340px] bg-black"></div>
                <p className="font-bold text-5xl pl-10 sm:pl-0">
                  <a href="/work_cases?year=all">ALL</a>
                  <span className="font-normal text-sm ml-5">
                    [{projectsData && projectsData.length} cases]
                  </span>
                </p>
              </div>
              {new Array(3).fill(0).map((_, i) => (
                <div key={i} className="space-y-6">
                  <div className="h-[3px] w-[100vw] sm:w-[340px] bg-black"></div>
                  <p className="font-bold text-5xl pl-10 sm:pl-0">
                    <a href={`/work_cases?year=${currentYear - i}`}>
                      <span ref={yearRef} className="rotate-backward">
                        {currentYear - i}
                      </span>
                    </a>
                    <span className="font-normal text-sm ml-5">
                      [
                      {projectsData &&
                        projectsData.filter(
                          (project: any) => project.year === currentYear - i
                        ).length}{" "}
                      cases]
                    </span>
                  </p>
                </div>
              ))}
              <div className="space-y-6">
                <div className="h-[3px] w-[100vw] sm:w-[340px] bg-black"></div>
                <p className="font-bold text-5xl pl-10 sm:pl-0">
                  <a href="/work_cases?year=past">PAST</a>
                  <span className="font-normal text-sm ml-5">
                    [
                    {projectsData &&
                      projectsData.filter(
                        (project: any) => project.year <= currentYear - 3
                      ).length}{" "}
                    cases]
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>

        <footer
          className="
             text-[15px] text-black text-center
             fixed
             inset-x-0
             bottom-0
             p-4"
        >
          @{new Date().getFullYear()} ALL RIGHTS RESERVED. NEEL BANSAL
        </footer>
      </div>
    </div>
  );
}
