/////////////////////////////////////
// IMPORTING LIBRARIES
/////////////////////////////////////

import client from "../client";
import React, {
  useContext,
  createContext,
  useState,
  useEffect,
  ReactNode,
} from "react";

// INTERFACE: AUTHCONTEXTTYPE
interface AuthContextType {
  projectsData: any;
  certificatesData: any;
  artProjectsData: any;
}

/////////////////////////////////////
// FUNCTION: SAVEDATATOLOCALSTORAGE
/////////////////////////////////////

const saveDataToLocalStorage = (key: string, data: Object): void => {
  localStorage.setItem(key, JSON.stringify({ data, timestamp: Date.now() }));
};

/////////////////////////////////////
// FUNCTION: ISDATASTALE
/////////////////////////////////////

const isDataStale = (key: string): boolean => {
  const savedData = JSON.parse(localStorage.getItem(key) || "{}");
  if (savedData && Date.now() - savedData.timestamp < 24 * 60 * 60 * 1000) {
    return false; // Data is not older than 24 hours
  }
  return true; // Data is older than 24 hours or not available
};

/////////////////////////////////////
// FUNCTION: USEDATA
/////////////////////////////////////

const DataContext = createContext<AuthContextType | undefined>(undefined);

export const useData = () => {
  const context = useContext(DataContext);
  if (context === undefined) {
    throw new Error("useData must be used within a DataProvider");
  }
  return context;
};

/////////////////////////////////////
// FUNCTION: DATAPROVIDER
/////////////////////////////////////

export const DataProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [projectsData, setProjectsData] = useState<any | null>(null);
  const [certificatesData, setCertificatesData] = useState<any | null>(null);
  const [artProjectsData, setArtProjectsData] = useState<any | null>(null);

  const fetchData = (key: string, fetchQuery: string, setData: Function) => {
    client
      .fetch<Array<Object>>(fetchQuery)
      .then((data: Array<Object>) => {
        saveDataToLocalStorage(key, data);
        setData(data);
      })
      .catch(console.error);
  };

  useEffect(() => {
    if (isDataStale("projectsData")) {
      fetchData("projectsData", '*[_type == "projects"]', setProjectsData);
    } else {
      const savedProjectsData = JSON.parse(
        localStorage.getItem("projectsData") || "{}"
      );
      setProjectsData(savedProjectsData.data);
    }

    if (isDataStale("certificatesData")) {
      fetchData(
        "certificatesData",
        '*[_type == "certificates"]',
        setCertificatesData
      );
    } else {
      const savedCertificatesData = JSON.parse(
        localStorage.getItem("certificatesData") || "{}"
      );
      setCertificatesData(savedCertificatesData.data);
    }

    if (isDataStale("artProjectsData")) {
      fetchData(
        "artProjectsData",
        '*[_type == "artProjects"]',
        setArtProjectsData
      );
    } else {
      const savedArtProjectsData = JSON.parse(
        localStorage.getItem("artProjectsData") || "{}"
      );
      setArtProjectsData(savedArtProjectsData.data);
    }
  }, []);

  const value: AuthContextType = {
    projectsData,
    certificatesData,
    artProjectsData,
  };

  return <DataContext.Provider value={value}>{children}</DataContext.Provider>;
};
