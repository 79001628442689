/////////////////////////////////////
// IMPORTING LIBRARIES
/////////////////////////////////////

import React from "react";
import { getBibliographies } from "../../api/api";
import { useLoading } from "../../helpers/LoadingContext";

/////////////////////////////////////
// COMPONENT: BIBLIOGRAPHIES
/////////////////////////////////////

const Bibliographies = () => {
  const { loading, setLoading } = useLoading();
  {
    /* ADD TO SANITY.IO */
  }

  const [bibliographies, setBibliographies] = React.useState(
    getBibliographies()
  );
  return (
    <div
      className="w-full h-full min-h-screen"
      style={{ backgroundColor: "#1C1D20" }}
    >
      <div className="max-w-6xl mx-auto py-20">
        <nav style={{ backgroundColor: "#1C1D20" }}>
          <div className="mx-auto max-w-full text-[12.5px]">
            <div className="flex h-20 items-center justify-between">
              <div className="sm:block text-white flex-1 items-center justify-center ">
                <h1 className="mb-10 sm:text-6xl text-5xl text-white">
                  Bibliographies
                </h1>
              </div>
              <div className=" sm:ml-6 sm:block">
                <div className="flex space-x-4">
                  <a
                    href="/#about"
                    className=" text-white px-3  py-2"
                    aria-current="page"
                  >
                    About
                  </a>

                  <a href="/work" className=" text-white px-3  py-2">
                    Work
                  </a>

                  <a href="/#contact" className=" text-white px-3  py-2">
                    Contact
                  </a>
                </div>
              </div>
            </div>
          </div>
        </nav>
        <br />
        <br />
        <h2
          data-scroll
          data-scroll-speed="2"
          data-scroll-delay="0.2"
          className="text-md font-normal  mb-3 text-white"
        >
          Hey there! I'm sorry, but I'm not sure where I got all the photos for
          my projects from. If you reach out to me on social media or by the
          contact page, I'll be sure to give you credit for any images that
          belong to you. I really appreciate your understanding. Just to let you
          know, these projects are just for personal use and won't be available
          for sale. <br />
          <br />
          Thanks again for your help!
        </h2>
        <br />
        <table className="text-white table-fixed w-full">
          <thead>
            <tr>
              <th
                className="text-left w-1/3 text-xl"
                style={{ borderRight: "5px solid white" }}
              >
                Item
              </th>
              <th
                className="text-left pl-5 w-1/3 text-xl"
                style={{ borderRight: "5px solid white" }}
              >
                Link
              </th>
              <th
                className="text-left pl-5 w-1/3 text-xl"
                style={{ width: "300px" }}
              >
                Reason
              </th>
            </tr>
          </thead>
          <tbody>
            {bibliographies.map(({ name, link, reason }): any => {
              return (
                <tr>
                  <td
                    style={{ borderRight: "5px solid white", width: "300px" }}
                    className="pr-5"
                  >
                    {name}
                  </td>
                  <td>
                    <a
                      target="_blank"
                      className="pl-5 text-white underline hover:text-white"
                      href={link}
                      style={{ width: "300px" }}
                    >
                      LINK
                    </a>
                  </td>
                  <td
                    className="pl-5"
                    style={{ width: "300px", borderLeft: "5px solid white" }}
                  >
                    {reason}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        {/*<div className="flex flex-row space-x-20 pt-10">
          <FiFacebook className="text-white text-3xl" />
          <FiLinkedin className="text-white text-3xl" />
          <FiInstagram className="text-white text-3xl" />
          <FiGithub className="text-white text-3xl" />
          <AiOutlineMail className="text-white text-3xl" />
          </div>*/}
      </div>
    </div>
  );
};

/////////////////////////////////////
// EXPORTING BIBLIOGRAPHIES
/////////////////////////////////////

export default Bibliographies;
