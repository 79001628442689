/////////////////////////////////////
// IMPORTING LIBRARIES / COMPONENTS
/////////////////////////////////////

import React, { useState } from "react";
import Navbar from "../../components/Navbar";
import Footer from "./Footer/Footer";
import Introduction from "./Introduction/Introduction";
import About from "./About/About";
import FeaturedWork from "./FeaturedWork/FeaturedWork";
import FeaturedCertificates from "./FeaturedCertificates/FeaturedCertificates";
import FeaturedUIDesigns from "./FeaturedUIDesigns/FeaturedUIDesigns";
import LoadingScreen from "./LoadingScreen/LoadingScreen";
import Tags from "../../components/Tags";
import FeaturedArt from "./FeaturedArt/FeaturedArt";

/////////////////////////////////////
// COMPONENT: APP
/////////////////////////////////////

const App = ({ scroll }: any) => {
  /**
   * Renders the App component with all its child components.
   *
   * @return {JSX.Element} The App component.
   */
  const [loading, setLoading] = useState<boolean>(true);

  return (
    <div className="overflow-hidden">
      <div className="overflow-auto overflow-x-hidden h-[100%]">
        <LoadingScreen />

        <div className="w-full">
          <Navbar />
          <Introduction />

          <About />
          <FeaturedWork scroll={scroll} />
        </div>
      </div>
      {/*<FeaturedArt />*/}

      <h2 className="ml-20 mb-10 uppercase font-bold">
        <Tags type="h2" text="featured certificates" />
      </h2>
      <FeaturedCertificates />

      <div>
        {/* <FeaturedUIDesigns /> */}
        <br />
        <br />
        <Footer />
      </div>
    </div>
  );
};

/////////////////////////////////////
// EXPORTING APP
/////////////////////////////////////

export default App;
