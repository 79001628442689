/////////////////////////////////////
// IMPORTING LIBRARIES
/////////////////////////////////////

import { Backdrop } from "@mui/material";
import { useState, useEffect } from "react";
import { useLoading } from "../../../helpers/LoadingContext";

/////////////////////////////////////
// COMPONENT: LOADINGSCREEN
/////////////////////////////////////

const LoadingScreen = () => {
  const { loading, setLoading } = useLoading();

  const [percentageLoaded, setPercentageLoaded] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setPercentageLoaded((percentage) => {
        if (percentage === 100) {
          clearInterval(intervalId);
          setLoading(false);
          return 100;
        } else {
          return percentage + 1;
        }
      });
    }, 10);

    return () => clearInterval(intervalId);
  }, []);

  const formattedPercentage = percentageLoaded.toString().padStart(3, "0");

  const currentScreenWidth = window.innerWidth;
  let blackBgStyle;
  if (currentScreenWidth > 768) {
    blackBgStyle = {
      height: `${percentageLoaded}%`,
    };
  } else {
    blackBgStyle = {
      width: `${percentageLoaded}vw`,
    };
  }

  return (
    <Backdrop
      sx={{
        overflow: "hidden",
        margin: "0px 0px 0px 0px",
        position: "fixed",
        top: 0,
        backgroundColor: "white",
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: (theme: { zIndex: { drawer: number } }) =>
          theme.zIndex.drawer + 1,
      }}
      open={loading}
    >
      <div className="md:flex-row flex-col h-[100vh] flex justify-center lg:space-x-64 md:space-x-32 md:space-y-0  space-y-20 items-center  max-w-7xl sm:max-w-full sm:m-20 bg-white">
        <p className="text-9xl">{formattedPercentage}%</p>
        <div
          className="md:w-[2.5px] w-[100vw] md:h-[100%] h-[2.5px] bg-black "
          style={blackBgStyle}
        />
        <p className="inline-flex">
          ©️ {new Date().getFullYear()} ALL RIGHTS RESERVED. NEEL BANSAL
        </p>
      </div>
    </Backdrop>
  );
};

/////////////////////////////////////
// EXPORTING LOADINGSCREEN
/////////////////////////////////////

export default LoadingScreen;
