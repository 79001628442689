/////////////////////////////////////
// IMPORTING LIBRARIES
/////////////////////////////////////

import { motion } from "framer-motion";
import React, { useEffect, useState, useRef } from "react";
import locomotiveScroll from "locomotive-scroll";
import { useLoading } from "../../../helpers/LoadingContext";
import { urlFor } from "../../../client";
import { isPhone } from "../../../helpers/helper";
import gsap from "gsap";

/////////////////////////////////////
// INTERFACE: PROPS
/////////////////////////////////////

interface Props {
  isHovered: boolean;
  currentWorkImage: string;
}

/////////////////////////////////////
// COMPONENT: PLAYBOX
/////////////////////////////////////

const PlayBox: React.FC<Props> = ({
  isHovered,
  currentWorkImage,
}): JSX.Element => {
  const { loading } = useLoading();
  const [mousePosition, setMousePosition] = useState({
    x: 0,
    y: 0,
  });

  const [scroll, setScroll] = useState({
    x: 0,
    y: 0,
  });

  const imageRef = useRef<any>(null);

  useEffect(() => {
    if (!loading && isPhone() === false) {
      const scrollInstance = new locomotiveScroll({
        el: document.querySelector("[data-scroll-container]") as HTMLElement,
        multiplier: 0.75,
        smooth: true,
        smartphone: {
          smooth: true,
        },
        getSpeed: true,
        getDirection: true,
        reloadOnContextChange: true,
      });

      const mouseMove = (e: { clientX: number; clientY: number }) => {
        setMousePosition({
          x: e.clientX,
          y: e.clientY,
        });
      };

      const scrollUpdate = (e: { scroll: { x: number; y: number } }) => {
        setScroll({
          x: e.scroll.x,
          y: e.scroll.y,
        });
      };

      window.addEventListener("mousemove", mouseMove);
      scrollInstance.on("scroll", scrollUpdate);

      return () => {
        window.removeEventListener("mousemove", mouseMove);
        scrollInstance.destroy();
      };
    }
  }, [loading]);

  useEffect(() => {
    if (imageRef.current) {
      gsap.to(imageRef.current, {
        duration: 0.2,
        opacity: 0,
        onComplete: () => {
          if (currentWorkImage) {
            imageRef.current.src = urlFor(currentWorkImage)
              .width(300)
              .height(200)
              .url();
            gsap.to(imageRef.current, { duration: 1, opacity: 1 });
          }
        },
      });
    }
  }, [currentWorkImage]);

  const cursorVariants = {
    hidden: {
      opacity: 0,
      scale: 0,
    },
    visible: {
      opacity: 1,
      scale: 1,
    },
  };

  const cursorTransition = {
    duration: 0.3,
  };

  return (
    <motion.div
      className="cursor w-[350px] h-[250px]"
      variants={cursorVariants}
      initial="hidden"
      animate={isHovered && currentWorkImage ? "visible" : "hidden"}
      transition={cursorTransition}
      style={{
        left: mousePosition.x - 145 + scroll.x,
        top: mousePosition.y - 120 + scroll.y,
        display: "block",
      }}
    >
      <div className="w-full h-full flex items-center justify-center">
        <div className="flex items-center justify-center w-[300px] h-[200px]">
          <img ref={imageRef} alt="playbox image" />
          <motion.div className="z-10 uppercase absolute h-[100px] w-[100px] font-bold text-white rounded-full bg-[#1C1D20] items-center justify-center font-mono opacity-90 shadow-2xl"></motion.div>
          <p className="z-20 uppercase absolute text-white">Play!</p>
        </div>
      </div>
    </motion.div>
  );
};

/////////////////////////////////////
// EXPORTING PLAYBOX
/////////////////////////////////////

export default PlayBox;
